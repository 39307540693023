
* {
  box-sizing: border-box;
}

body {
  background-color: #1c1f42;
  color: white;
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.root {
  display: flex;
  flex-direction: column;

}

header {
  background-color:  #333333;
  display: flex;
  padding: 1rem;
  border-radius: 5px; 
  justify-content: flex-end;
}

.search {
  font-family: inherit;
  font-size: 1.2rem;
  background-color: transparent;
  color: white;
  padding: 0.5rem, 1.5rem;
  border-radius: 50px; 
  padding: .5rem;
}
.movie {
  background-color: #333333;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 1rem;
  width: 300px;
}

.movie img {
  object-fit: cover;
  height: 450px;
  width: 100%;
}

.movie-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.movie-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.movie-info h3 {
  margin: 0;
}

.movie-over {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #333333;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  max-height: 100%;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
}

.movie:hover .movie-over {
  transform: translateY(0%);
}

.tag {
  background-color: #4f505f;
  padding: 0.5rem;
  border-radius: 5px;
  
}

.tag.green{
  color: rgb(27, 235, 27);
  font-weight: bold;
}

.tag.orange {
  color: orange;
  font-weight: bold;
}

.tag.red {
  font-weight: bold;
  color: rgb(218, 0, 0);
}